import React from 'react'
import PageWrapper from "../components/PageWrapper";
import LandingBanner from '../sections/landing/MainB';
import LandingAbout from '../sections/landing/landingAbout.js';
import LandingReviews from '../sections/landing/landingReviews.js';
import Rte from "../sections/landing/popular";
import AC from '../sections/landing/Acontentone';
import ACthree from '../sections/meso/mesotwo';
import ACfour from '../sections/meso/mesothree';
import ACfive from '../sections/landing/acfive';
import ACt from '../sections/beard/contentbe';
import CTA from '../sections/non/noncta.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Nongallery from "../sections/non/nongallery.js";
import Lan from "../sections/rhino/rhinohero.js";
import Testimonial from "../sections/advanced/advreviews";
import Anchoring from "../sections/rhino/anchor.js";
import Saveface from "../sections/landing/saveface";
import RhinoQu from "../sections/rhino/rhinoquestion";
import Recovery from "../sections/beard/reoverybe";
import Abanner from '../sections/landing/Alanding';
import Blo from '../sections/landing/blo.js';
import Facts from '../sections/landing/lf.js';
import counter from '../sections/countersection/countersection.js'
import Sli from '../sections/rhino/rhinoslide';
import Pro from '../sections/landing/steps.js';
import Beauty from "../sections/non/nonbeauty.js";
import Acco from "../sections/beard/faqbe";
import PRPres from '../sections/prp/prpresults'
import HeroDesktop from "../sections/beard/herobe";
import ComboB from "../sections/beard/combobe";
import HeroImage from "../sections/non/nonmobile";
import HeroMobile from "../sections/non/noncontent";
import BeardPro from '../sections/prp/prppro'
import Acthree from '../sections/landing/acthree';
import SectionOne from '../sections/countersection/countersection.js'
import Clinic from '../sections/rhino/rhinofind.js';  

const BeardPage = () => ( 
  <>
 <Head title="PRP Beard Restoration in London" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Nose jobs, Liquid nose job, Liquid rhinoplasty, non Surgical nose job London, Nose filler, Non surgical rhinoplasty, Non surgical nose job, Pain free nose job, non surgical rhinoplasty London, non surgical nose job harley street"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
      <HeroDesktop />
   
      


      <ACt />
      
   
      <Recovery />
     

    <BeardPro />
    <Testimonial />


    <Pro />

  <Acco />

   <Rte />
   <Clinic /> 
   <Saveface />
 
     </PageWrapper>
   
  </>
)
export default BeardPage
